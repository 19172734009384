import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';

type Props = {
  title?: string | undefined;
  description?: string | null | undefined;
  image?: string | null | undefined;
  article: boolean;
  template?: boolean;
};

const SEO: FC<Props> = ({ title, description, image, article, template }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const { titleTemplate, defaultDescription, siteUrl, defaultImage } = site.siteMetadata;

  const seo = {
    title: title,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  let tmpl = template ? titleTemplate : undefined;

  return (
    <Helmet title={seo.title} titleTemplate={tmpl}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && <meta property="og:description" content={seo.description} />}
      {seo.image && <meta property="og:image" content={seo.image} />}
      {seo.description && <meta name="twitter:description" content={seo.description} />}
      {seo.image && <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
};

export default SEO;

SEO.defaultProps = {
  title: '',
  description: null,
  image: null,
  article: false,
  template: true,
};

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`;
